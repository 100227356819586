import { Stack, Typography } from '@mui/material';
import React from 'react';
import { color } from '../elements';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const Section09 = ({ isMobile }) => {
  return (
    <Stack backgroundColor={color.white} spacing={3} my={10} p={2}>
      <Typography
        textAlign={'center'}
        whiteSpace={'pre-line'}
        lineHeight={1.2}
        fontWeight={'bold'}
        fontFamily={'Pretendard'}
        letterSpacing={-0.8}
        my={3}
        mx={'auto'}
        fontSize={isMobile ? '1.5rem' : '2rem'}
      >
        {`인터넷 가입 핵심 질문 3가지`}
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.2}
            fontWeight={400}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
          >
            <Typography
              component={'span'}
              fontSize={isMobile ? '1.1rem' : '1.5rem'}
              whiteSpace={'pre-line'}
              lineHeight={1.2}
              fontWeight={'bold'}
              fontFamily={'Pretendard'}
              letterSpacing={-0.8}
              my={2}
            >
              {'01. '}
            </Typography>
            인터넷 품질은 본사와 같나요?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.5}
            fontWeight={300}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
            px={4}
          >
            {`본사와 다른 점은 오직 혜택 뿐이에요.\n 인터넷 품질과 월 요금은 물론A/S도 동일합니다.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.2}
            fontWeight={400}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
          >
            <Typography
              component={'span'}
              fontSize={isMobile ? '1.1rem' : '1.5rem'}
              whiteSpace={'pre-line'}
              lineHeight={1.2}
              fontWeight={'bold'}
              fontFamily={'Pretendard'}
              letterSpacing={-0.8}
              my={2}
            >
              {'02. '}
            </Typography>
            돈을 왜 주는거에요? 불법 아니에요?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.5}
            fontWeight={300}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
            px={4}
          >
            {`현금 사은품은 신규 고객 유지를 위해 통신사가 대리점에 제공하는 고객
            페이백 비용입니다.현금 사은품을 제공받는 것은 당연한 권리입니다!`}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.2}
            fontWeight={400}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
          >
            <Typography
              component={'span'}
              fontSize={isMobile ? '1.1rem' : '1.5rem'}
              whiteSpace={'pre-line'}
              lineHeight={1.2}
              fontWeight={'bold'}
              fontFamily={'Pretendard'}
              letterSpacing={-0.8}
              my={2}
            >
              {'03. '}
            </Typography>
            요금제 비싼 거 강요하는거 아니에요?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
          <Typography
            fontSize={isMobile ? '1.1rem' : '1.5rem'}
            whiteSpace={'pre-line'}
            lineHeight={1.5}
            fontWeight={300}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={2}
            px={4}
          >
            {`영자통신에서는 비싼 요금을 강요하지 않습니다. 판매상품에 따라 상담원 고과에 전혀 반영하지 않아요. 기본적으로 최저요금설계를 기본 원칙으로 하고있습니다.`}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default Section09;
