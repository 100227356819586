import React from 'react';
import { Typography, Stack } from '@mui/material';
import { color } from '../elements';

const Footer = () => {
  return (
    <Stack
      py={10}
      px={5}
      bgcolor={color.common_black}
      color={color.white}
      letterSpacing={-0.8}
    >
      <Typography variant="h6" gutterBottom>
        영자통신
      </Typography>
      <Typography variant="body1">대표자 : 이효미</Typography>
      <Typography variant="body1">
        주소: 서울특별시 영등포구 영신로 247
      </Typography>
      <Typography variant="body1">사업자등록번호: 681-45-00945 </Typography>
      <Typography variant="body1">
        통신판매업 신고번호: 제2023-서울영등포-1387호
      </Typography>
      <Typography variant="body1">대표번호: 1666-3904</Typography>
      <Typography variant="body1">이메일: llia@naver.com</Typography>
      <Typography variant="body2">
        © 2022 영자통신. All Rights Reserved.
      </Typography>
    </Stack>
  );
};

export default Footer;
