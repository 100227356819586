import React, { useRef } from 'react';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { Footer } from '../components';
import {
  Container,
  Fade,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';

import { sectionImages } from '../images';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

import { color } from '../elements';
import {
  Favorite,
  Home,
  Info,
  Settings,
  Phone,
  Paid,
} from '@mui/icons-material';
import CommonImage from '../components/CommonImage';

const Noti = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  const cardData = [
    {
      subtitle: 'STEP 01',
      title: '인터넷가입\n상담 및 접수',
      description: '이것은 첫 번째 카드입니다.',
      icon: <Phone />,
    },
    {
      subtitle: 'STEP 02',
      title: '신청서 작성',
      description: '이것은 첫 번째 카드입니다.',
      icon: <Home />,
    },
    {
      subtitle: 'STEP 03',
      title: '휴대폰 인증\n & 본사접수',
      description: '이것은 두 번째 카드입니다.',
      icon: <Favorite />,
    },
    {
      subtitle: 'STEP 04',
      title: '지역설치팀과\n 일정조율',
      description: '이것은 세 번째 카드입니다.',
      icon: <Info />,
    },
    {
      subtitle: 'STEP 05',
      title: '인터넷 설치\n 완료',
      description: '이것은 네 번째 카드입니다.',
      icon: <Settings />,
    },
    {
      subtitle: 'STEP 06',
      title: '설치당일\n 현금지급',
      description: '이것은 네 번째 카드입니다.',
      icon: <Paid />,
    },
  ];
  return (
    <>
      <ResponsiveAppBar isMobile={isMobile} />
      <Container disableGutters maxWidth="md">
        <Fade in={isVisible} timeout={1000}>
          <div ref={sectionRef}>
            <CommonImage
              maxWidth={900}
              width="100%"
              margin="auto"
              src={isMobile ? sectionImages.NotiMb : sectionImages.NotiPC}
            />
          </div>
        </Fade>
        <Typography
          textAlign={'center'}
          whiteSpace={'pre-line'}
          lineHeight={1.2}
          fontWeight={'bold'}
          fontFamily={'Pretendard'}
          letterSpacing={-0.8}
          mt={10}
          mx={'auto'}
          fontSize={'1.5rem'}
        >
          {'오늘신청하고 내일 바로쓰는 \n인터넷 가입절차'}
        </Typography>
        <Typography
          textAlign={'center'}
          whiteSpace={'pre-line'}
          lineHeight={1.2}
          fontWeight={400}
          fontFamily={'Pretendard'}
          letterSpacing={-0.8}
          my={4}
          mx={'auto'}
          fontSize={'1.2rem'}
        >
          {
            '지역,날씨 등 설치 환경 및 설치 시간에 따라\n 조정이 필요할 수도 있습니다.'
          }
        </Typography>
        <Grid my={3} container rowGap={3} spacing={1}>
          {cardData.map((card, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Stack
                bgcolor={'#541c7c'}
                justifyContent={'center'}
                alignItems={'center'}
                height={'200px'}
              >
                <Stack sx={{ bgcolor: color.white, borderRadius: 50, mt: 3 }}>
                  <IconButton color="primary">{card.icon}</IconButton>
                </Stack>
                <Stack py={2} textAlign={'center'}>
                  <Typography
                    whiteSpace={'pre-line'}
                    lineHeight={1.2}
                    fontWeight={300}
                    fontFamily={'Pretendard'}
                    letterSpacing={-0.8}
                    color={color.white}
                    fontSize={'1rem'}
                    mb={1}
                  >
                    {card.subtitle}
                  </Typography>
                  <Typography
                    whiteSpace={'pre-line'}
                    lineHeight={1.2}
                    fontWeight={'bold'}
                    fontFamily={'Pretendard'}
                    letterSpacing={-0.8}
                    color={color.white}
                    fontSize={'1rem'}
                    variant="h5"
                  >
                    {card.title}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Footer />
    </>
  );
};

export default Noti;
