import React, { useEffect } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
// import ShareIcon from '@mui/icons-material/Share';

import { sectionImages } from '../images';

// import { color } from '../elements';
// import ReactPlayer from 'react-player';
import {
  Section01,
  Section01v2,
  Section02,
  Section02Form,
  Section02Form3,
  Section03,
  Section04,
  Section05,
  Section06,
  Section07,
  Section08,
  Section09,
  Section10,
  Section11,
  Section12,
  Footer,
} from '../components';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Section01v3 from '../components/Section01v3';
import Section01v4 from '../components/Section01v4';

const sectionImagesList = Object.values(sectionImages);

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'));
  // const goToCloudikeHome = (link) => {
  //   window.location.href = link;
  // };

  const hoverImgPreload = (images) => {
    images.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };
  // const handleShareClick = async () => {
  //   // 공유 기능을 추가하는 로직을 여기에 추가하세요.
  //   const test = {
  //     title: '스포츠다이브',
  //     text: '스포츠다이브',
  //     url: window.location.href,
  //   };

  //   if (navigator.share) {
  //     navigator.share(test);
  //     await navigator.clipboard?.writeText(window.location.href);
  //   } else {
  //     await navigator.clipboard?.writeText(window.location.href);
  //     alert('링크가 공유되었습니다.');
  //   }
  // };
  useEffect(() => {
    hoverImgPreload(sectionImagesList);
  }, []);
  return (
    <>
      {/* 메인섹션 */}
      <ResponsiveAppBar isMobile={isMobile} />
      <Container disableGutters maxWidth="lg">
        <Section01 isMobile={isMobile} />
        <Section01v2 isMobile={isMobile} />
        <Section01v3 isMobile={isMobile} />
        {/* <Section01v4 isMobile={isMobile} /> */}
        {/* <Section02Form2 isMobile={isMobile} isDesktop={isDesktop} /> */}
        {/* <Section02Form isMobile={isMobile} isDesktop={isDesktop} /> */}
        <Section02Form3 isMobile={isMobile} isDesktop={isDesktop} />
        <Section02 isMobile={isMobile} isDesktop={isDesktop} />

        <Section06 isMobile={isMobile} />
        <Section07 isMobile={isMobile} />
        <Section03 isMobile={isMobile} />
        <Section04 isMobile={isMobile} />
        <Section05 isMobile={isMobile} />
        <Section08 isMobile={isMobile} />
        <Section09 isMobile={isMobile} />
        <Section10 isMobile={isMobile} />
        <Section02Form3 isMobile={isMobile} isDesktop={isDesktop} />
        <Section11 isMobile={isMobile} />
        <Section12 isMobile={isMobile} />
      </Container>
      <Footer isMobile={isMobile} />
    </>
  );
};

export default Home;
