import React, { useState } from "react";
import { Container, useMediaQuery, useTheme } from "@mui/material";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import {
  Paper,
  Stack,
  Typography,
  Button,
  TextField,
  Divider,
} from "@mui/material";
import { Footer } from "../components";
import { color } from "../elements";
import CommonImage from "../components/CommonImage";
import { sectionImages } from "../images";
import { useMutation } from "react-query";
import axios from "axios";

function trackFacebookPixel(event) {
  if (typeof window.fbq === "function") {
    window.fbq("track", event);
  }
}

const InternetJoin = ({ isMobile, calcData, handleClick }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={3}
        mx={"auto"}
        fontSize={"1rem"}
      >
        1. 인터넷 가입 어떤게 필요하세요?
      </Typography>
      <Stack direction={"row"} mt={4} spacing={1}>
        <Button
          sx={{
            fontSize: "1rem",
            letterSpacing: -0.8,
            py: 2,
            backgroundColor: calcData["internet_new"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["internet_new"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["internet_new"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="internet_new"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          신규가입
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            letterSpacing: -0.8,
            backgroundColor: calcData["internet_change"]
              ? "#a76dd1"
              : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["internet_change"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["internet_change"]
                ? "#a76dd1"
                : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="internet_change"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          통신사 변경
        </Button>
      </Stack>
      <Stack
        mt={1}
        px={2}
        py={4}
        bgcolor={color.common_gray_05}
        borderRadius={1}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant="h6" fontWeight={"bold"} letterSpacing={-0.8}>
          {calcData.internet_new
            ? "신규가입이 필요하세요?"
            : "통신사 변경이 필요하세요?"}
        </Typography>
        <Typography letterSpacing={-0.8}>
          {calcData.internet_new
            ? `현재 사용중인 인터넷이 없어서\n 새롭게 인터넷을 가입하는 거에요`
            : "현재 사용중인 통신사에서 다른 통신사로 \n변경하는 거에요. 예시) KT > LGU+"}
        </Typography>
      </Stack>
    </>
  );
};
const InternetProduct = ({ isMobile, calcData, handleClick }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mt={8}
        mx={"auto"}
        fontSize={"1rem"}
      >
        2. 상품을 선택해 주세요.
      </Typography>
      <Stack direction={"row"} my={3} spacing={1}>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            letterSpacing: -0.8,
            backgroundColor: calcData["product_internet"]
              ? "#a76dd1"
              : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["product_internet"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["product_internet"]
                ? "#a76dd1"
                : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="product_internet"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          인터넷
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            letterSpacing: -0.8,
            backgroundColor: calcData["product_internet_tv"]
              ? "#a76dd1"
              : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["product_internet_tv"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["product_internet_tv"]
                ? "#a76dd1"
                : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="product_internet_tv"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          인터넷 + TV
        </Button>
      </Stack>
    </>
  );
};
const InternetSpeed = ({ isMobile, calcData, handleClick }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mt={8}
        mx={"auto"}
        fontSize={"1rem"}
      >
        3. 인터넷 속도를 선택해주세요.
      </Typography>
      <Stack direction={"row"} my={3} spacing={1}>
        <Button
          sx={{
            py: 2,
            letterSpacing: -0.8,
            fontSize: isMobile ? "0.7rem" : "1rem",
            whiteSpace: "pre-line",
            backgroundColor: calcData["speed_100"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["speed_100"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["speed_100"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="speed_100"
          onClick={(e) => {
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          {`100mb\n 1~2인 가구 \n대부분 가능\n(영상포함)`}
        </Button>
        <Button
          sx={{
            py: 2,
            letterSpacing: -0.8,
            fontSize: isMobile ? "0.7rem" : "1rem",
            whiteSpace: "pre-line",
            backgroundColor: calcData["speed_500"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["speed_500"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["speed_500"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="speed_500"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          {`500mb\n게임을 즐기는\n2~4인 가구`}
        </Button>
        <Button
          sx={{
            py: 2,
            letterSpacing: -0.8,
            fontSize: isMobile ? "0.7rem" : "1rem",
            whiteSpace: "pre-line",
            backgroundColor: calcData["speed_1000"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["speed_1000"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["speed_1000"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="speed_1000"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          {`1GB\n 업무용(재택근무) \n또는 인터넷방송`}
        </Button>
      </Stack>
    </>
  );
};
const InternetCompany = ({ isMobile, calcData, handleClick }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mt={8}
        mx={"auto"}
        fontSize={"1rem"}
      >
        4. 인터넷 통신사를 선택해주세요
      </Typography>
      <Stack direction={"row"} my={3} spacing={1}>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",

            backgroundColor: calcData["company_lg"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["company_lg"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["company_lg"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_lg"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              pointerEvents: "none",
            }}
            src={sectionImages.LGLogo}
          />
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            backgroundColor: calcData["company_sk"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["company_sk"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["company_sk"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_sk"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              pointerEvents: "none",
            }}
            src={sectionImages.SKLogo}
          />
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            backgroundColor: calcData["company_kt"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["company_kt"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["company_kt"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_kt"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              // mr: 1,
              pointerEvents: "none",
            }}
            src={sectionImages.KTLogo}
          />
        </Button>
      </Stack>
    </>
  );
};
const CombinePhone = ({ isMobile, calcData, handleClick }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mt={8}
        mx={"auto"}
        fontSize={"1rem"}
      >
        5. 핸드폰을 결합할까요?
      </Typography>
      <Stack direction={"row"} my={3} spacing={1}>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            whiteSpace: "pre-line",
            letterSpacing: -0.8,
            backgroundColor: calcData["combine_true"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["combine_true"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["combine_true"] ? "#a76dd1" : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="combine_true"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          결합 할인 적용
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: "1rem",
            whiteSpace: "pre-line",
            letterSpacing: -0.8,
            backgroundColor: calcData["combine_false"] ? "#a76dd1" : "inherit", // 'main'은 테마의 주 색상입니다.
            color: calcData["combine_false"] ? color.white : "inherit",
            "&:hover": {
              backgroundColor: calcData["combine_false"]
                ? "#a76dd1"
                : "inherit", // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="combine_false"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          적용 안 함
        </Button>
      </Stack>
    </>
  );
};
const Contact = ({ phone, handlePhone }) => {
  return (
    <>
      <Typography
        textAlign={"start"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mt={8}
        mx={"auto"}
        fontSize={"1rem"}
      >
        6. 연락받으실 전화번호를 입력해주세요.
      </Typography>
      <Stack direction={"row"} my={3} spacing={1}>
        <TextField
          fullWidth
          value={phone}
          name="phone"
          placeholder="전화번호를 입력해주세요"
          onChange={handlePhone}
        />
      </Stack>
    </>
  );
};
const Result = ({ isMobile, calcData, handleClick }) => {
  const formatKRW = (number) => {
    if (!number) return 0;
    return new Intl.NumberFormat("ko-KR", {
      style: "currency",
      currency: "KRW",
    }).format(number);
  };

  const calculator = () => {
    const cases = {
      // ------lg
      "combine_true#company_lg#product_internet#speed_100": {
        price: 16500,
        bonus: 250000,
      },
      "combine_false#company_lg#product_internet#speed_100": {
        price: 22000,
        bonus: 250000,
      },
      "combine_true#company_lg#product_internet#speed_500": {
        price: 23100,
        bonus: 330000,
      },
      "combine_false#company_lg#product_internet#speed_500": {
        price: 33000,
        bonus: 330000,
      },
      "combine_true#company_lg#product_internet#speed_1000": {
        price: 25300,
        bonus: 330000,
      },
      "combine_false#company_lg#product_internet#speed_1000": {
        price: 38500,
        bonus: 330000,
      },
      "combine_true#company_lg#product_internet_tv#speed_100": {
        price: 33000,
        bonus: 400000,
      },
      "combine_false#company_lg#product_internet_tv#speed_100": {
        price: 38500,
        bonus: 400000,
      },
      "combine_true#company_lg#product_internet_tv#speed_500": {
        price: 34100,
        bonus: 470000,
      },
      "combine_false#company_lg#product_internet_tv#speed_500": {
        price: 44000,
        bonus: 470000,
      },
      "combine_true#company_lg#product_internet_tv#speed_1000": {
        price: 36300,
        bonus: 470000,
      },
      "combine_false#company_lg#product_internet_tv#speed_1000": {
        price: 39500,
        bonus: 470000,
      },
      // ------sk
      "combine_true#company_sk#product_internet#speed_100": {
        price: 17600,
        bonus: 90000,
      },
      "combine_false#company_sk#product_internet#speed_100": {
        price: 22000,
        bonus: 90000,
      },
      "combine_true#company_sk#product_internet#speed_500": {
        price: 22000,
        bonus: 130000,
      },
      "combine_false#company_sk#product_internet#speed_500": {
        price: 33000,
        bonus: 130000,
      },
      "combine_true#company_sk#product_internet#speed_1000": {
        price: 25300,
        bonus: 170000,
      },
      "combine_false#company_sk#product_internet#speed_1000": {
        price: 38500,
        bonus: 170000,
      },
      "combine_true#company_sk#product_internet_tv#speed_100": {
        price: 30800,
        bonus: 310000,
      },
      "combine_false#company_sk#product_internet_tv#speed_100": {
        price: 34100,
        bonus: 310000,
      },
      "combine_true#company_sk#product_internet_tv#speed_500": {
        price: 35200,
        bonus: 350000,
      },
      "combine_false#company_sk#product_internet_tv#speed_500": {
        price: 41800,
        bonus: 350000,
      },
      "combine_true#company_sk#product_internet_tv#speed_1000": {
        price: 38500,
        bonus: 350000,
      },
      "combine_false#company_sk#product_internet_tv#speed_1000": {
        price: 47300,
        bonus: 350000,
      },
      // ------kt
      "combine_true#company_kt#product_internet#speed_100": {
        price: 16500,
        bonus: 90000,
      },
      "combine_false#company_kt#product_internet#speed_100": {
        price: 22000,
        bonus: 90000,
      },
      "combine_true#company_kt#product_internet#speed_500": {
        price: 22000,
        bonus: 110000,
      },
      "combine_false#company_kt#product_internet#speed_500": {
        price: 33000,
        bonus: 110000,
      },
      "combine_true#company_kt#product_internet#speed_1000": {
        price: 27500,
        bonus: 140000,
      },
      "combine_false#company_kt#product_internet#speed_1000": {
        price: 38500,
        bonus: 140000,
      },
      "combine_true#company_kt#product_internet_tv#speed_100": {
        price: 31900,
        bonus: 370000,
      },
      "combine_false#company_kt#product_internet_tv#speed_100": {
        price: 37400,
        bonus: 370000,
      },
      "combine_true#company_kt#product_internet_tv#speed_500": {
        price: 37400,
        bonus: 450000,
      },
      "combine_false#company_kt#product_internet_tv#speed_500": {
        price: 42900,
        bonus: 450000,
      },
      "combine_true#company_kt#product_internet_tv#speed_1000": {
        price: 42900,
        bonus: 450000,
      },
      "combine_false#company_kt#product_internet_tv#speed_1000": {
        price: 48400,
        bonus: 450000,
      },
    };
    const filteredKeys = Object.keys(calcData)
      .filter(
        (k) =>
          k !== "internet_change" &&
          k !== "internet_new" &&
          k !== "name" &&
          calcData[k]
      )
      .sort()
      .join("#");
    return cases[filteredKeys] ?? "";
  };

  return (
    <Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          textAlign={"center"}
          whiteSpace={"pre-line"}
          lineHeight={1.2}
          fontWeight={"bold"}
          fontFamily={"Pretendard"}
          letterSpacing={-0.8}
          py={1}
          // color={'secondary'}
          fontSize={"1rem"}
        >
          예상 캐시백
        </Typography>
        <Typography
          textAlign={"center"}
          whiteSpace={"pre-line"}
          lineHeight={1.2}
          fontWeight={"bold"}
          fontFamily={"Pretendard"}
          letterSpacing={-0.8}
          py={1}
          color={"red"}
          fontSize={"1rem"}
        >{`${formatKRW(calculator()?.bonus) ?? 0} 원`}</Typography>
      </Stack>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography
          textAlign={"center"}
          whiteSpace={"pre-line"}
          lineHeight={1.2}
          fontWeight={"bold"}
          fontFamily={"Pretendard"}
          letterSpacing={-0.8}
          py={1}
          fontSize={"1rem"}
        >
          예상 요금
        </Typography>
        <Typography
          textAlign={"center"}
          whiteSpace={"pre-line"}
          lineHeight={1.2}
          fontWeight={"bold"}
          fontFamily={"Pretendard"}
          letterSpacing={-0.8}
          py={2}
          color={"red"}
          fontSize={"1rem"}
        >{`월 ${formatKRW(calculator()?.price) ?? 0} 원`}</Typography>
      </Stack>
    </Stack>
  );
};

const Calc = () => {
  const init = {
    internet_new: false,
    internet_change: false,
    product_internet: false,
    product_internet_tv: false,
    speed_100: false,
    speed_500: false,
    speed_1000: false,
    company_lg: false,
    company_sk: false,
    company_kt: false,
    combine_true: false,
    combine_false: false,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [calcData, setCalcData] = useState(init);
  const [phone, setPhone] = useState("");

  const postData = useMutation((data) =>
    axios.post(
      "https://zyn423xxia.execute-api.ap-northeast-2.amazonaws.com/dev/calc",
      data
    )
  );
  const handleClick = (name) => {
    const _calcData = { ...calcData };
    switch (name) {
      case "internet_new":
        _calcData.internet_new = true;
        _calcData.internet_change = false;
        break;
      case "internet_change":
        _calcData.internet_new = false;
        _calcData.internet_change = true;
        break;
      case "product_internet":
        _calcData.product_internet = true;
        _calcData.product_internet_tv = false;
        break;
      case "product_internet_tv":
        _calcData.product_internet = false;
        _calcData.product_internet_tv = true;
        break;
      case "speed_100":
        _calcData.speed_100 = true;
        _calcData.speed_500 = false;
        _calcData.speed_1000 = false;
        break;
      case "speed_500":
        _calcData.speed_100 = false;
        _calcData.speed_500 = true;
        _calcData.speed_1000 = false;
        break;
      case "speed_1000":
        _calcData.speed_100 = false;
        _calcData.speed_500 = false;
        _calcData.speed_1000 = true;
        break;
      case "company_lg":
        _calcData.company_lg = true;
        _calcData.company_sk = false;
        _calcData.company_kt = false;
        break;
      case "company_sk":
        _calcData.company_lg = false;
        _calcData.company_sk = true;
        _calcData.company_kt = false;
        break;
      case "company_kt":
        _calcData.company_lg = false;
        _calcData.company_sk = false;
        _calcData.company_kt = true;
        break;
      case "combine_true":
        _calcData.combine_true = true;
        _calcData.combine_false = false;
        break;
      case "combine_false":
        _calcData.combine_true = false;
        _calcData.combine_false = true;
        break;
      default:
        break;
    }

    setCalcData(_calcData);
  };

  const handlePhone = (e) => {
    if (/^[0-9]*$/.test(e.target.value)) setPhone(e.target.value);
  };

  const handleSubmit = () => {
    const result = Object.fromEntries(
      Object.entries(calcData).filter(([k, v]) => calcData[k])
    );

    if (Object.keys(result).length === 5 && phone !== "") {
      const dataToSend = { ...result, phone };
      postData.mutate(dataToSend);
      setCalcData(init);
      setPhone("");
      window.karrotPixel.track("SubmitApplication");
      if (!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "s_55a5f9e5c1f1";
      if (!_nasa) var _nasa = {};
      _nasa["cnv"] = window.wcs.cnv("4", "1");
      window.wcs_do(_nasa);
      trackFacebookPixel("CompleteRegistration");

      alert("상담신청이 완료되었습니다!");
    }
  };
  return (
    <>
      <ResponsiveAppBar isMobile={isMobile} />
      <Container disableGutters maxWidth="md">
        <Stack p={2}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <InternetJoin
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <InternetProduct
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <InternetSpeed
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <InternetCompany
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <CombinePhone
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <Contact
              isMobile={isMobile}
              calcData={calcData}
              phone={phone}
              handleClick={handleClick}
              handlePhone={handlePhone}
            />
            <Divider sx={{ my: 5 }} />
            <Result
              isMobile={isMobile}
              calcData={calcData}
              handleClick={handleClick}
            />
            <Button
              fullWidth
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={postData.isLoading}
            >
              <Typography
                py={1}
                fontFamily={"Pretendard"}
                fontWeight={"bold"}
                fontSize={"1.5rem"}
              >
                비밀지원금 상담신청
              </Typography>
            </Button>
          </Paper>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default Calc;
