import { Fade } from '@mui/material';
import React, { useRef } from 'react';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section12 = () => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  return (
    <Fade in={isVisible} timeout={1000}>
      <div ref={sectionRef}>
        <a
          href="tel:010-2932-5904"
          style={{ backgroundColor: '#ffc45f', cursor: 'pointer' }}
        >
          <CommonImage
            width="100%"
            sx={{ mt: -1, cursor: 'pointer' }}
            src={sectionImages.Section12Main}
          />
        </a>
      </div>
    </Fade>
  );
};

export default Section12;
