import {
  Box,
  Button,
  Checkbox,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import { sectionImages } from '../images';
import { useMutation } from 'react-query';
import axios from 'axios';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';
import { color } from '../elements';
import { Phone } from '@mui/icons-material';
function trackFacebookPixel(event) {
  if (typeof window.fbq === 'function') {
    window.fbq('track', event);
  }
}
const InternetCompany = ({ isMobile, formData, handleClick }) => {
  console.log(formData);
  return (
    <Stack>
      <Typography
        textAlign={'start'}
        whiteSpace={'pre-line'}
        lineHeight={1.2}
        fontWeight={'bold'}
        fontFamily={'Pretendard'}
        letterSpacing={-0.8}
        // mx={'auto'}
        // fontSize={'1.2rem'}
      >
        Q. 희망 통신사를 선택해 주세요!
      </Typography>
      <Stack direction={'row'} my={1} spacing={1}>
        <Button
          sx={{
            py: 1,
            fontSize: '1rem',

            backgroundColor: formData['company_lg'] ? '#8d57ef' : 'inherit', // 'main'은 테마의 주 색상입니다.
            color: formData['company_lg'] ? color.white : 'inherit',
            '&:hover': {
              backgroundColor: formData['company_lg'] ? '#8d57ef' : 'inherit', // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_lg"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              pointerEvents: 'none',
            }}
            src={sectionImages.LGLogo}
          />
        </Button>
        <Button
          sx={{
            py: 1,
            fontSize: '1rem',
            backgroundColor: formData['company_sk'] ? '#8d57ef' : 'inherit', // 'main'은 테마의 주 색상입니다.
            color: formData['company_sk'] ? color.white : 'inherit',
            '&:hover': {
              backgroundColor: formData['company_sk'] ? '#8d57ef' : 'inherit', // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_sk"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              pointerEvents: 'none',
            }}
            src={sectionImages.SKLogo}
          />
        </Button>
        <Button
          sx={{
            py: 1,
            fontSize: '1rem',
            backgroundColor: formData['company_kt'] ? '#8d57ef' : 'inherit', // 'main'은 테마의 주 색상입니다.
            color: formData['company_kt'] ? color.white : 'inherit',
            '&:hover': {
              backgroundColor: formData['company_kt'] ? '#8d57ef' : 'inherit', // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="company_kt"
          onClick={(e) => {
            e.preventDefault();
            handleClick(e.target.name);
          }}
          fullWidth
          variant="outlined"
        >
          <CommonImage
            sx={{
              width: isMobile ? 70 : 100,
              // mr: 1,
              pointerEvents: 'none',
            }}
            src={sectionImages.KTLogo}
          />
        </Button>
      </Stack>
    </Stack>
  );
};

const InternetProduct = ({ isMobile, formData, handleClick }) => {
  return (
    <Stack mt={2}>
      <Typography
        textAlign={'start'}
        whiteSpace={'pre-line'}
        lineHeight={1.2}
        fontWeight={'bold'}
        fontFamily={'Pretendard'}
        letterSpacing={-0.8}
        // mx={'auto'}
        // fontSize={'1.2rem'}
      >
        Q. 원하시는 상품을 선택해주세요!
      </Typography>
      <Stack direction={'row'} my={1} spacing={1}>
        <Button
          sx={{
            py: 2,
            fontSize: '1rem',
            letterSpacing: -0.8,
            backgroundColor: formData['product_internet']
              ? '#8d57ef'
              : 'inherit', // 'main'은 테마의 주 색상입니다.
            color: formData['product_internet'] ? color.white : 'inherit',
            '&:hover': {
              backgroundColor: formData['product_internet']
                ? '#8d57ef'
                : 'inherit', // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="product_internet"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          인터넷
        </Button>
        <Button
          sx={{
            py: 2,
            fontSize: '1rem',
            letterSpacing: -0.8,
            backgroundColor: formData['product_internet_tv']
              ? '#8d57ef'
              : 'inherit', // 'main'은 테마의 주 색상입니다.
            color: formData['product_internet_tv'] ? color.white : 'inherit',
            '&:hover': {
              backgroundColor: formData['product_internet_tv']
                ? '#8d57ef'
                : 'inherit', // 버튼 호버 시에도 동일한 스타일 적용
            },
          }}
          name="product_internet_tv"
          onClick={(e) => handleClick(e.target.name)}
          fullWidth
          variant="outlined"
        >
          인터넷 + TV
        </Button>
      </Stack>
    </Stack>
  );
};

const CustomTextField = ({ isMobile, label, textfield }) => {
  return (
    <Stack my={-1.5}>
      <Stack
        direction={'row'}
        bgcolor={color.white}
        alignItems={'center'}

        // border={`1px solid ${color.common_gray_04}`}
      >
        <Stack px={1} minWidth={isMobile ? 60 : 120}>
          <Typography
            fontWeight={'bold'}
            whiteSpace={'pre-line'}
            textAlign={'start'}
          >
            {label}
          </Typography>
        </Stack>
        <Stack flexGrow={1} px={1} py={2} bgcolor={color.white}>
          {textfield}
        </Stack>
      </Stack>
    </Stack>
  );
};

const Section02Form3 = ({ isMobile, isDesktop }) => {
  const initObj = {
    name: '',
    phoneTop: '010',
    phoneMid: '',
    phoneEnd: '',
    // product_internet: false,
    // product_internet_tv: false,
    // company_lg: false,
    // company_sk: false,
    // company_kt: false,
    agreeToTerms: true,
  };

  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  const postData = useMutation((data) =>
    axios.post(
      'https://zyn423xxia.execute-api.ap-northeast-2.amazonaws.com/dev/simple-form',
      data
    )
  );
  const [formData, setFormData] = useState(initObj);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClick = (name) => {
    const _formData = { ...formData };
    switch (name) {
      case 'product_internet':
        _formData.product_internet = true;
        _formData.product_internet_tv = false;
        break;
      case 'product_internet_tv':
        _formData.product_internet = false;
        _formData.product_internet_tv = true;
        break;
      case 'company_lg':
        _formData.company_lg = true;
        _formData.company_sk = false;
        _formData.company_kt = false;
        break;
      case 'company_sk':
        _formData.company_lg = false;
        _formData.company_sk = true;
        _formData.company_kt = false;
        break;
      case 'company_kt':
        _formData.company_lg = false;
        _formData.company_sk = false;
        _formData.company_kt = true;
        break;
      default:
        break;
    }
    console.log(_formData);
    setFormData(_formData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // if (!formData.company_lg && !formData.company_sk && !formData.company_sk) {
    //   alert(`통신사를 선택해주세요!`);
    //   return;
    // }

    const validField = Object.keys(formData)
      .filter((k) => typeof formData[k] !== 'boolean')
      .find((k) => !Boolean(formData[k]));

    if (validField) {
      alert(`${validField === 'name' ? '이름을' : '연락처를'} 입력해주세요!`);
      return;
    }

    if (!formData.agreeToTerms) {
      alert(`개인정보 수집이용에 동의해주세요!`);
      return;
    }
    const { phoneTop, phoneMid, phoneEnd, ...otherFormData } = formData;

    postData.mutate({
      ...otherFormData,
      phone: phoneTop + phoneMid + phoneEnd,
    });
    setFormData(initObj);
    window.karrotPixel.track('SubmitApplication');
    if (!wcs_add) var wcs_add = {};
    wcs_add['wa'] = 's_55a5f9e5c1f1';
    if (!_nasa) var _nasa = {};
    _nasa['cnv'] = window.wcs.cnv('4', '1');
    window.wcs_do(_nasa);
    trackFacebookPixel('CompleteRegistration');
    alert('상담신청이 완료되었습니다!');
  };
  return (
    <Box sx={{ my: -1 }} bgcolor={'#8d56ef'}>
      <Stack bgcolor={color.white} py={5}>
        {/* <InternetCompany
          isMobile={isMobile}
          formData={formData}
          handleClick={handleClick}
        />
        <InternetProduct
          isMobile={isMobile}
          formData={formData}
          handleClick={handleClick}
        /> */}
        {/* <Divider sx={{ my: 2 }} /> */}
        <CustomTextField
          isMobile={isMobile}
          label={'이름'}
          textfield={
            <TextField
              InputProps={{
                sx: {
                  borderRadius: 0, // border-radius를 0으로 설정하여 사각형 모양으로 변경
                },
              }}
              sx={{
                '& fieldset': { border: 'none' },
                border: '1px solid #ccc',
              }}
              fullWidth
              placeholder="성함을 입력해주세요"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          }
        />
        <CustomTextField
          label={'연락처'}
          isMobile={isMobile}
          textfield={
            <Stack direction={'row'} gap={1}>
              <TextField
                fullWidth
                select
                name="phoneTop"
                value={formData.phoneTop}
                onChange={handleChange}
                sx={{
                  maxWidth: 150,
                  '& fieldset': { border: 'none' },
                  border: '1px solid #ccc',
                }}
              >
                <MenuItem value="010">010</MenuItem>
                <MenuItem value="011">011</MenuItem>
                <MenuItem value="016">016</MenuItem>
                <MenuItem value="017">017</MenuItem>
                <MenuItem value="018">018</MenuItem>
                <MenuItem value="019">019</MenuItem>
              </TextField>
              <TextField
                fullWidth
                name="phoneMid"
                value={formData.phoneMid}
                onChange={handleChange}
                inputProps={{ maxLength: 4 }}
                sx={{
                  '& fieldset': { border: 'none' },
                  border: '1px solid #ccc',
                }}
              />
              <TextField
                fullWidth
                name="phoneEnd"
                value={formData.phoneEnd}
                onChange={handleChange}
                inputProps={{ maxLength: 4 }}
                sx={{
                  '& fieldset': { border: 'none' },
                  border: '1px solid #ccc',
                }}
              />
            </Stack>
          }
        />

        <FormControlLabel
          sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}
          control={
            <Checkbox
              checked={formData.agreeToTerms}
              onChange={handleChange}
              name="agreeToTerms"
            />
          }
          label="개인정보 수집·이용에 동의합니다"
        />
        <Typography
          textAlign={'center'}
          letterSpacing={-0.8}
          color={'gray'}
          lineHeight={isMobile && 0.8}
          fontSize={isMobile ? '7px' : '14px'}
        >
          - 수집 및 이용목적 : 가입신청 및 상담
        </Typography>
        <Typography
          textAlign={'center'}
          letterSpacing={-0.8}
          color={'gray'}
          fontSize={isMobile ? '7px' : '14px'}
        >
          - 수집 및 이용목적 : 이름,연락처,접속 IP정보
        </Typography>
        <Typography
          textAlign={'center'}
          letterSpacing={-0.8}
          color={'gray'}
          lineHeight={isMobile && 0.8}
          fontSize={isMobile ? '7px' : '14px'}
        >
          {`- 수집 및 이용목적 : 개통완료시 D+1095일, 단순상담 시 D+14일 식별불가 암호화 파쇄`}
        </Typography>

        <Box
          display={'flex'}
          margin={'auto'}
          maxWidth={'70%'}
          mt={4}
          justifyContent={'center'}
        >
          <CommonImage
            onClick={handleSubmit}
            width="100%"
            sx={{
              my: 0,
              cursor: 'pointer',
              // animation: 'pulse 1s infinite',
              // '@keyframes pulse': {
              //   '0%, 50%': { transform: 'scale(1)' },
              //   '50%': { transform: 'scale(0.9)' },
              // },
            }}
            src={sectionImages.Section02FormButton}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Section02Form3;
