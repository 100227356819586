const generateRandomKoreanName = () => {
  // 성(姓)을 위한 문자 배열
  const familyNames = [
    '김',
    '이',
    '박',
    '최',
    '정',
    '강',
    '조',
    '윤',
    '장',
    '임',
  ];

  // 이름을 위한 문자 배열
  const givenNames = [
    '민',
    '지',
    '현',
    '수',
    '영',
    '유',
    '선',
    '은',
    '혜',
    '우',
    '하',
    '도',
    '서',
    '준',
    '호',
    '경',
    '재',
    '희',
    '빈',
    '연',
  ];

  // 랜덤한 성과 이름 선택
  const familyName =
    familyNames[Math.floor(Math.random() * familyNames.length)];
  const givenName = givenNames[Math.floor(Math.random() * givenNames.length)];

  // 이름 조합
  return familyName + '*' + givenName;
};

const generateRandomPhoneNumber = () => {
  // 랜덤 네 자리 숫자 생성
  const middleNumbers = Math.floor(Math.random() * 10000)
    .toString()
    .padStart(4, '0');

  // 전화번호 형식으로 변환
  return `010-${middleNumbers}-****`;
};

const generateApplyData = () => {
  return {
    date: new Date().toLocaleDateString('en-CA'),
    name: generateRandomKoreanName(),
    phone: generateRandomPhoneNumber(),
    status: '상담완료',
  };
};

export default generateApplyData;
