import React, { useState } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useMediaQuery, useTheme, Dialog, DialogContent } from "@mui/material";
// import ShareIcon from '@mui/icons-material/Share';
import DaumPostcode from "react-daum-postcode";
import axios from "axios";

import {
  Container,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
  MenuItem,
  Box,
  Button,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Stack,
} from "@mui/material";

import { Footer, Section03 } from "../components";
import { color } from "../elements";
import { useMutation } from "react-query";

function trackFacebookPixel(event) {
  if (typeof window.fbq === "function") {
    window.fbq("track", event);
  }
}

const CustomTextField = ({ isMobile, label, textfield }) => {
  return (
    <>
      <Stack
        direction={"row"}
        bgcolor={color.common_gray_06}
        alignItems={"center"}
        border={`1px solid ${color.common_gray_04}`}
      >
        <Stack px={1} minWidth={isMobile ? 80 : 120}>
          <Typography whiteSpace={"pre-line"} textAlign={"center"}>
            {label}
          </Typography>
        </Stack>
        <Stack flexGrow={1} px={3} py={2} bgcolor={color.white}>
          {textfield}
        </Stack>
      </Stack>
    </>
  );
};

const Form = () => {
  const initObj = {
    name: "",
    birthdate: "",
    carrier: "sk",
    phoneNumber: "",
    address: "",
    detailAddress: "",
    product: "",
    contractTerm: "3년약정",
    currentCarrier: "",
    additionalRequest: "",
    agreeToTerms: false,
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const postData = useMutation((data) =>
    axios.post(
      "https://zyn423xxia.execute-api.ap-northeast-2.amazonaws.com/dev/form",
      data
    )
  );
  const [formData, setFormData] = useState(initObj);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setFormData((prevState) => ({
      ...prevState,
      address: fullAddress,
    }));
    handleClose();
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validField = Object.keys(formData).find((k) => !Boolean(formData[k]));
    if (validField) {
      alert(`${validField} 없음`);
      return;
    }

    postData.mutate(formData);
    setFormData(initObj);
    trackFacebookPixel("CompleteRegistration");
    window.karrotPixel.track("SubmitApplication");
    if (!wcs_add) var wcs_add = {};
    wcs_add["wa"] = "s_55a5f9e5c1f1";
    if (!_nasa) var _nasa = {};
    _nasa["cnv"] = window.wcs.cnv("4", "1");
    window.wcs_do(_nasa);
    trackFacebookPixel("CompleteRegistration");
    alert("상담신청이 완료되었습니다!");

    // console.log(formData);
    // postData.mutate(formData);

    //

    // Form submission logic here
  };

  return (
    <>
      <ResponsiveAppBar isMobile={isMobile} />

      <Container disableGutters maxWidth="md">
        <Section03 />
        <Stack my={1} p={3} border={`1px solid ${color.common_gray_05}`}>
          <Stack py={2} alignItems={"center"} direction={"row"}>
            <CircularProgress size={isMobile ? 30 : 40} thickness={10} />
            <Typography
              color={"primary"}
              letterSpacing={-0.8}
              fontFamily={"Pretendard"}
              fontWeight={"bold"}
              variant="h6"
              ml={2}
            >
              AWS SSL 보안서버 구동중
            </Typography>
          </Stack>
          <Typography
            letterSpacing={-0.8}
            fontFamily={"Pretendard"}
            whiteSpace={"pre-line"}
            variant="body1"
          >
            {`개인정보를 어설프게 활용하거나, 경솔하게 취급하지 않습니다. 모든 개인정보는 고시된 사항에 100% 부합되게 활용되며, 
              AWS SSL 보안서버를 통해 외부로 개인정보가 유출되는 일이 없습니다.`}
          </Typography>
        </Stack>
        <Typography
          letterSpacing={-0.8}
          fontFamily={"Pretendard"}
          fontWeight={"bold"}
          variant="h4"
          textAlign={"center"}
          fontStyle={"Pretend"}
          sx={{ my: 4, py: 2, color: color.white, bgcolor: "#541c7c" }}
        >
          가입 신청서
        </Typography>

        <form onSubmit={handleSubmit}>
          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography
              letterSpacing={-0.8}
              fontFamily={"Pretendard"}
              my={1}
              fontWeight={"bold"}
              variant="h6"
            >
              가입자 정보
            </Typography>
            <CustomTextField
              isMobile={isMobile}
              label={"가입자명"}
              textfield={
                <TextField
                  fullWidth
                  placeholder="가입자명"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              }
            />
            <CustomTextField
              label={"생년월일"}
              isMobile={isMobile}
              textfield={
                <TextField
                  fullWidth
                  placeholder="생년월일"
                  name="birthdate"
                  type="number"
                  value={formData.birthdate}
                  onChange={handleChange}
                />
              }
            />
            <CustomTextField
              label={"연락처"}
              isMobile={isMobile}
              textfield={
                <Stack direction={"row"} gap={1}>
                  <TextField
                    fullWidth
                    select
                    name="carrier"
                    value={formData.carrier}
                    onChange={handleChange}
                    sx={{ maxWidth: 70 }}
                  >
                    <MenuItem value="sk">SK</MenuItem>
                    <MenuItem value="lg">LG</MenuItem>
                    <MenuItem value="kt">KT</MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    label="본인명의핸드폰 번호"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Stack>
              }
            />

            <CustomTextField
              label={"설치주소"}
              isMobile={isMobile}
              textfield={
                <div>
                  <TextField
                    sx={{ mb: 2 }}
                    label="주소"
                    name="address"
                    value={formData.address}
                    onClick={handleOpen}
                    fullWidth
                  />
                  <TextField
                    label="상세 주소"
                    name="detailAddress"
                    value={formData.detailAddress}
                    fullWidth
                    onChange={handleChange}
                  />
                  <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                      <DaumPostcode onComplete={handleComplete} />
                    </DialogContent>
                  </Dialog>
                </div>
              }
            />
          </Paper>

          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography
              fontFamily={"Pretendard"}
              my={1}
              fontWeight={"bold"}
              variant="h6"
            >
              상품 정보
            </Typography>
            <CustomTextField
              label={"가입상품"}
              isMobile={isMobile}
              textfield={
                <TextField
                  fullWidth
                  select
                  label="가입상품"
                  name="product"
                  value={formData.product}
                  onChange={handleChange}
                >
                  <MenuItem value="100m">100M단독</MenuItem>
                  <MenuItem value="500m">500M단독</MenuItem>
                  <MenuItem value="1000m">1G단독</MenuItem>
                  <MenuItem value="100mt">100M인터넷+TV</MenuItem>
                  <MenuItem value="500mt">500M인터넷+TV</MenuItem>
                  <MenuItem value="1000mt">1G인터넷+TV</MenuItem>
                </TextField>
              }
            />
            <CustomTextField
              label={"약정기간"}
              isMobile={isMobile}
              textfield={
                <FormControl component="fieldset" margin="normal">
                  <RadioGroup
                    row
                    name="contractTerm"
                    value={formData.contractTerm}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="3년약정"
                      control={<Radio />}
                      label="3년약정"
                    />
                  </RadioGroup>
                </FormControl>
              }
            />
            <CustomTextField
              label={"이용중인\n통신사"}
              isMobile={isMobile}
              textfield={
                <TextField
                  fullWidth
                  placeholder="예)SK, 지역케이블 등"
                  select
                  label="이용중인 통신사"
                  name="currentCarrier"
                  value={formData.currentCarrier}
                  onChange={handleChange}
                >
                  <MenuItem value="not">없다</MenuItem>
                  <MenuItem value="lg">LG유플러스</MenuItem>
                  <MenuItem value="sk">SK브로드밴드</MenuItem>
                  <MenuItem value="kt">KT</MenuItem>
                  <MenuItem value="sky">스카이라이프</MenuItem>
                  <MenuItem value="lghv">LG헬로비전</MenuItem>
                </TextField>
              }
            />
          </Paper>

          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography
              fontFamily={"Pretendard"}
              my={1}
              fontWeight={"bold"}
              variant="h6"
            >
              기타사항
            </Typography>
            <CustomTextField
              label={"문의요청사항"}
              isMobile={isMobile}
              textfield={
                <TextField
                  fullWidth
                  label="요청사항"
                  placeholder="지원금문의 관련"
                  name="additionalRequest"
                  multiline
                  rows={4}
                  value={formData.additionalRequest}
                  onChange={handleChange}
                />
              }
            />
          </Paper>

          <Paper sx={{ p: 2, mb: 2 }}>
            <Typography
              fontFamily={"Pretendard"}
              my={1}
              fontWeight={"bold"}
              variant="h6"
            >
              개인정보 수집·이용 동의
            </Typography>
            <Stack border={`1px solid ${color.common_gray_04}`}>
              <Typography
                maxHeight={200}
                overflow={"auto"}
                p={2}
                whiteSpace={"pre-line"}
              >
                {`수집하는 개인정보의 항목
회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.

- 수집항목 : 고객명, 생년월일, 휴대폰, 통신사, 가입상품, 약정, 설치장소, 문의사항
- 개인정보 수집방법 : 홈페이지(회원가입)
개인정보의 수집 및 이용목적
회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.

- 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산
구매 및 요금 결제, 물품배송 또는 청구지 등 발송

- 회원 관리
회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 연령확인

개인정보의 보유 및 이용기간
회사는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.`}
              </Typography>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.agreeToTerms}
                  onChange={handleChange}
                  name="agreeToTerms"
                />
              }
              label="개인정보 수집·이용에 동의합니다"
            />
          </Paper>

          <Box sx={{ mt: 2, px: 10, py: 2 }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={postData.isLoading}
            >
              <Typography fontSize={isMobile ? 18 : 32}>제출</Typography>
            </Button>
          </Box>
        </form>
      </Container>

      <Footer />
    </>
  );
};

export default Form;
