import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { Footer } from '../components';
import { color } from '../elements';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useQuery } from 'react-query';

const VIEW_MODE = {
  CALC: 'calc',
  FORM: 'form',
  SIMPLE_FORM: 'simpleForm',
};

const Admin = () => {
  const [key, setKey] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [view, setView] = useState(VIEW_MODE.CALC);
  const { isLoading, data, refetch } = useQuery(
    'get-product',
    () => {
      return axios.get(
        'https://zyn423xxia.execute-api.ap-northeast-2.amazonaws.com/dev/admin'
      );
    },
    {
      enabled: false, // 자동으로 실행되지 않도록 설정
    }
  );

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      // 선택한 탭이 '요금계산'일 때
      setView(VIEW_MODE.CALC);
    } else if (newValue === 1) {
      // 선택한 탭이 '가입신청'일 때
      setView(VIEW_MODE.FORM);
    } else if (newValue === 2) {
      // 선택한 탭이 '가입신청'일 때
      setView(VIEW_MODE.SIMPLE_FORM);
    }
  };

  const matchKey = 'admin1234!!';
  const signInHandler = (e) => {
    setKey(e.target.value);
  };
  const validate = () => {
    if (key === matchKey) {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      refetch(); // isAdmin이 true가 되면 데이터를 가져옴
    }
  }, [isAdmin, refetch]);
  if (isLoading) {
    return (
      <Container>
        <Stack height={'100vh'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Stack>
      </Container>
    );
  }

  return (
    <>
      <ResponsiveAppBar />
      {isAdmin ? (
        <Stack height={'100vh'} my={2}>
          <Tabs
            variant="fullWidth"
            value={view}
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: '#541c7c' },
            }}
            sx={{
              border: '1px solid #541c7c',
              width: '100%', // 화면 전체 너비
              height: 70, // 높이 50
              display: 'flex',
              justifyContent: 'center', // 탭 가운데 정렬
              alignItems: 'center',
            }}
          >
            <Tab
              label="요금계산"
              sx={{
                py: 5,
                flex: 1, // 탭 너비를 절반씩 꽉 채우기
                textAlign: 'center', // 탭 텍스트 가운데 정렬
                border: '1px solid #541c7c',
                bgcolor: view === VIEW_MODE.CALC ? '#541c7c' : color.white,
                color: view === VIEW_MODE.CALC ? color.white : '#541c7c',
              }}
            />
            <Tab
              label="가입신청"
              sx={{
                y: 5,
                flex: 1, // 탭 너비를 절반씩 꽉 채우기
                textAlign: 'center', // 탭 텍스트 가운데 정렬
                bgcolor: view === VIEW_MODE.FORM ? '#541c7c' : color.white,
                color: view === VIEW_MODE.FORM ? color.white : '#541c7c',
              }}
            />
            <Tab
              label="간편상담신청"
              sx={{
                y: 5,
                flex: 1, // 탭 너비를 절반씩 꽉 채우기
                textAlign: 'center', // 탭 텍스트 가운데 정렬
                bgcolor:
                  view === VIEW_MODE.SIMPLE_FORM ? '#541c7c' : color.white,
                color: view === VIEW_MODE.SIMPLE_FORM ? color.white : '#541c7c',
              }}
            />
          </Tabs>

          <Box sx={{ height: '100%', width: '100%' }}>
            {view === VIEW_MODE.CALC && data?.data[VIEW_MODE.CALC] && (
              <DataGrid
                rows={data?.data[VIEW_MODE.CALC].rows.map(
                  ({ createdAt, ...others }) => ({
                    ...others,
                    createdAt: new Date(createdAt).toLocaleString('ko-KR'),
                  })
                )}
                columns={data?.data[VIEW_MODE.CALC].columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
            {view === VIEW_MODE.FORM && data?.data[VIEW_MODE.FORM] && (
              <DataGrid
                rows={data?.data[VIEW_MODE.FORM].rows.map(
                  ({ createdAt, ...others }) => ({
                    ...others,
                    createdAt: new Date(createdAt).toLocaleString('ko-KR'),
                  })
                )}
                columns={data?.data[VIEW_MODE.FORM].columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
              />
            )}
            {view === VIEW_MODE.SIMPLE_FORM &&
              data?.data[VIEW_MODE.SIMPLE_FORM] && (
                <DataGrid
                  rows={data?.data[VIEW_MODE.SIMPLE_FORM].rows.map(
                    ({ createdAt, ...others }) => ({
                      ...others,
                      createdAt: new Date(createdAt).toLocaleString('ko-KR'),
                    })
                  )}
                  columns={data?.data[VIEW_MODE.SIMPLE_FORM].columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              )}
          </Box>
        </Stack>
      ) : (
        <Container disableGutters maxWidth="md">
          <Stack height={'100vh'} my={2}>
            <Typography
              textAlign={'center'}
              whiteSpace={'pre-line'}
              lineHeight={1.2}
              fontWeight={'bold'}
              fontFamily={'Pretendard'}
              letterSpacing={-0.8}
              my={7}
              mx={'auto'}
              fontSize={'2rem'}
            >
              영자통신
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <TextField
                label="로그인"
                onChange={signInHandler}
                fullWidth
              ></TextField>
              <Button
                onClick={validate}
                sx={{ bgcolor: '#111', color: color.white }}
                variant="outlined"
              >
                Sign In
              </Button>
            </Stack>
          </Stack>
        </Container>
      )}

      <Footer />
    </>
  );
};

export default Admin;
