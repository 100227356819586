import { Box, Fade } from '@mui/material';
import React, { useRef } from 'react';
import { sectionImages } from '../images';

import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section06 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  return (
    <Fade in={isVisible} timeout={1000}>
      <Box my={8} ref={sectionRef}>
        <CommonImage
          width="100%"
          sx={{ mt: -1 }}
          src={sectionImages.Section06Main}
        />
      </Box>
    </Fade>
  );
};

export default Section06;
