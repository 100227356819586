import { Fade } from "@mui/material";
import React, { useRef } from "react";
import { sectionImages } from "../images";

import CommonImage from "./CommonImage";
import useFadeInOnScroll from "../controller/useFadeInOnScroll";

const Section02 = ({ isMobile, isDesktop }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  return (
    <CommonImage
      width="100%"
      sx={{ mt: -1 }}
      src={sectionImages.Section02Main}
    />
  );
};

export default Section02;
