import { Box, Fade } from '@mui/material';
import React, { useRef } from 'react';
import { sectionImages } from '../images';
import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';
const Section01v2 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  return (
    <Fade in={isVisible} timeout={1000}>
      <div ref={sectionRef}>
        <Box
          component="div"
          sx={{
            mt: -1,
            width: '100%',
            height: 0, // 높이를 0으로 설정
            paddingTop: '109.375%', // 이미지 비율에 대한 padding-top 값
            backgroundImage: `url(${sectionImages.Section0102Main})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        >
          <CommonImage
            width="60vw"
            maxWidth="500px"
            sx={{
              mt: -1,
              position: 'absolute',
              right: '0',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            src={sectionImages.Section0102Text}
          />
          {/* <CommonImage
            onClick={() => {
              navigate('/calc');
              window.scrollTo(0, 0);
            }}
            width="40vw"
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              right: '0',
              top: '75%',
              transform: 'translateY(-50%)',
            }}
            src={sectionImages.Section0102Btn}
          /> */}
        </Box>
      </div>
    </Fade>
  );
};

export default Section01v2;
