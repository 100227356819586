import React, { useState } from 'react';
import { Container, useMediaQuery, useTheme } from '@mui/material';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { Stack, Typography } from '@mui/material';
import { color } from '../elements';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { Footer } from '../components';
import CommonImage from '../components/CommonImage';
import { sectionImages } from '../images';

const Qna = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [expanded, setExpanded] = useState(true);

  // Accordion 토글 함수
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <ResponsiveAppBar isMobile={isMobile} />
      <Container disableGutters maxWidth="md">
        <CommonImage width="100%" src={sectionImages.Qna3} />

        <Stack backgroundColor={color.white} my={2} p={2}>
          <Typography
            textAlign={'center'}
            whiteSpace={'pre-line'}
            lineHeight={1.2}
            fontWeight={'bold'}
            fontFamily={'Pretendard'}
            letterSpacing={-0.8}
            my={5}
            mx={'auto'}
            fontSize={isMobile ? '1.5rem' : '1.5rem'}
          >
            {`인터넷 신규가입시 Q & A 필독사항`}
          </Typography>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                ✅️ 가입조건은 어떻게 되나요?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`신규가입이라면 가능합니다.
              현재 사용중인 인터넷이 없으시거나(신규), 현재 설치받으실 주소지에 이용중인 통신사가 아닌 타사로 신규가입(변경)한다면 혜택을 받아보실 수 있습니다.

              ※ 신규가입조건이 불가한경우
              동일 통신사로 이전설치가 가능함에도 불구하고,해지후 동일명의는 물론 가족간의 명의로 바꿔 기존에 사용했던 통신사로 신규가입하는것은 부정적 재가입으로 신규가입에 해당되지않습니다.
              단, 기존 인터넷은 유지하는 조건으로 다른주소지에 추가 신규설치하시는것은 가능합니다.

              본사 방침이 강화되어 부정가입 문제로 최근 이사 고객의 경우 동일주소 동일통신사 해지3개월미만 이력이나 동일주소 사용중인 회선이 확인될경우 실 가입자 확인을 위해 본사에서 요구하는 등본 or 부동산계약서가 필요할수있습니다. 
              
              • 통신 미납&체납이 있으신경우, 채무불이행등록고객
              • 만 18세미만 미성년자는 가입이 불가합니다.
              `}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                ✅️ 가입접수는 어떻게 진행되나요?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`고객님들께서 상담을 받으신후 혜택과 조건이 마음에 드셨다면 가입시 필요한 최소한의 정보가 확인되어야하며, 본인인증을 거친후 본사전산접수가 진행됩니다.
              
              ※ 가입해피콜시 모든 통화 내용은 녹취됩니다.
              가입진행 및 개통이후 발생할수 있는 문제를 대비해 필수적으로 진행하고있습니다. (고객님들께서 가입녹취가 필요하시다하면 별도 발송해드리고있습니다.)
              
              ※ 가입자의 성함, 주민번호, 설치받을주소, 자동이체 및 사은품 받으실 계좌번호 확인이 필요합니다. 
              또한 명의도용방지를 위한 본인인증이 필수로 진행되며, 해당 부분이 확인 되지않을시 모든 통신사 가입이 제한됩니다.
              
              ※ 인터넷 가입은 후불방식의 요금납부이기때문에 가입자정보의 통신미납, 체납, 결합정보, 다회선 등등 본사 심사를 거쳐 접수까지 진행됩니다. 
              그 과정에 있어 일반적인경우 해피콜 진행후 본사 전산에 정상적으로 접수되기까지 다이렉트로 10분내외로 진행되는경우도 있으시지만, 그렇지 않으신경우들도 종종 있습니다. 
              고객님께서 3년동안 사용하실 상품인 만큼 고객님께서도 추후 문제되지 않도록 사전에 정확한 확인후 진행하시는게 가장 안전합니다. 
              모든 문제가없을시 정상적으로 접수되며 가입번호가 부여된후 스케줄연락을 받게됩니다.
              
              ※ 개인정보보호법에 의거 통신서비스 가입을 위한 제공목적으로만 활용되며 1년이후 모든 개인정보는 폐기됩니다.
              `}
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                ✅️ 설치는 언제되나요?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`모든 통신사 설치일정은 전산접수 및 심사완료 후 해당 지역 센터에서 직접 스케줄러가 전화를 드립니다.
              빠르면 당일설치도 가능하며, 평일 기준 1-2일정도 소요되고 해당 지역센터에 따라, 기후에 따라 상황은 변동될수있습니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                ✅️ 사은품은 언제 받나요?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`현금 사은품은 개통당일지급을 원칙으로 하고있습니다.
              사은품 요청은 취합기준시간 17시까지 말씀해주시면 전산개통확인후 당일 18~19시사이 당일지급처리해드리며, 17시이후 요청건들은 익일날 당일요청하신분들과 합산하여 입금시간에 지급해드리고있습니다. 
              만약 토요일이나 공휴일에 설치가되시면 전산확인이 불가하므로 월요일에 확인하여 정해진 지급 시간에 입금해드립니다.
              상품권은 개통후 본사에서 직접 발송하며, 평일기준 2-3일이내로 받아보실수있습니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                ✅️ 본인인증은 어떻게하나요?
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`본인명의 휴대폰이 있으시다면 쉽고 간편하게 휴대폰 으로 인증이 가능합니다.
              만약 본인명의 휴대폰이 없으신 경우라면 신분증을 발송해주셔야합니다. (뒷자리 마스킹처리후 발송)
              유.무선 통신가입 절차는 모든 전 통신사 동일하며, 오프라인 모든 통신사 가입은 동일 방식으로 가입진행이됩니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded} onChange={toggleAccordion}>
            <AccordionSummary
              expandIcon={<ArrowDownwardIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography
                fontSize={'1.1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.2}
                fontWeight={400}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
              >
                📢 필수안내사항
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: color.common_gray_08 }}>
              <Typography
                fontSize={'1rem'}
                whiteSpace={'pre-line'}
                lineHeight={1.5}
                fontWeight={300}
                fontFamily={'Pretendard'}
                letterSpacing={-0.8}
                my={2}
                px={4}
              >
                {`1. 모든 가입상품은 기본 3년 약정이며, 약정기간내 해지시 위약금이 청구됩니다.

2. 1년이내 가입상품 하향 변경이나 해지 및 정지, 명의변경, 지로납부 변경시 받으신 사은품은 사유불문 전액 환수됩니다.

※ 본사통한 위면해지시에도 수수료는 전액환수되므로 받으신 사은품은 전액 환수됩니다.

3.  복지할인&교육청할인은 개통 1년이후 본사통해 신청가능합니다.

4. 현금사은품을 목적으로 하는 명의 도용 및 부정적 재가입을 숨기고 가입하는경우 현금사은품 전액환수되며, 이를 이행하지않을시 민형사상의 법적 고발로 넘어갈수있습니다.

※ 요금지원형 알뜰상품의 경우 [선납할인권] 적용시 환수기준에 포함됩니다.
매월 선납할인금액x해지월기준 합산되어 금액 환수됩니다.

5. 모든 상품은 최초 설치시 가입설치비용이 1회 청구됩니다.
설치비는 가입상품과 통신사에 따라 다를수있으며, 본사 홈페이지에서 확인 가능합니다.

6. 휴대폰결합은 인터넷개통후 본인이 직접 본사통해 결합해주셔야합니다.

7. 기존 사용중이었던 인터넷상품은 고객님께서 직접 해지해주셔야합니다.

8. 본 영자통신을 통한 최초가입상품이 아닌 
단독적으로 진행한 추가 프로모션이나 혜택, 결합으로 인해 요금이 달라질수있으며 이에 대한 부분은 책임지지않습니다.`}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Container>
      <Footer />
    </>
  );
};

export default Qna;
