import { Box, Fade } from '@mui/material';
import React, { useRef } from 'react';
import { sectionImages } from '../images';
import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section01 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  return (
    <Fade in={isVisible} timeout={1000}>
      <div ref={sectionRef}>
        <Box
          component="div"
          sx={{
            width: '100%',
            height: 0, // 높이를 0으로 설정
            paddingTop: '104.6975%', // 이미지 비율에 대한 padding-top 값
            backgroundImage: `url(${sectionImages.Section0101Main})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
          }}
        >
          <CommonImage
            width="100%"
            sx={{
              ml: 2,
              position: 'absolute', // 추가 이미지에 대한 위치 지정
              left: 0, // 왼쪽 가장자리
              top: '50%', // 상위 Box의 상단으로부터 50% 위치
              transform: 'translateY(-50%)', // 세로 중앙 정렬
              width: 'auto', // 너비 자동 조절
              height: '50%', // 높이 설정 (필요에 따라 조정)
            }}
            src={sectionImages.Section0101Text}
          />
        </Box>
      </div>
    </Fade>
  );
};

export default Section01;
