import { Stack, Typography, Grid } from "@mui/material";
import React from "react";
import "../css/Section08.css";

import generateApplyData from "../controller/generateApplyData";
import { color } from "../elements";

const Section08 = ({ isMobile }) => {
  const initArr = Array.from({ length: 50 }, generateApplyData);
  // const [rows, setRows] = useState(initArr);
  // useEffect(() => {
  //   const marqueeInterval = setInterval(() => {
  //     setRows((prevItems) => {
  //       return [...prevItems, generateApplyData()];
  //     });
  //   }, 1000); // 아이템이 3초마다 한 번씩 돌아갑니다.

  //   return () => clearInterval(marqueeInterval);
  // }, []);

  return (
    <Stack backgroundColor={"#ffcd49"} p={isMobile ? 1 : 3}>
      <Typography
        textAlign={"center"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        my={4}
        mx={"auto"}
        py={1}
        px={5}
        sx={{ bgcolor: "black" }}
        color={color.white}
        fontSize={isMobile ? "1.3rem" : "2rem"}
        borderRadius={10}
      >
        {`믿고 상담주시는 만큼`}
      </Typography>
      <Typography
        textAlign={"center"}
        whiteSpace={"pre-line"}
        lineHeight={1.2}
        fontWeight={"bold"}
        fontFamily={"Pretendard"}
        letterSpacing={-0.8}
        mb={4}
        mx={"auto"}
        fontSize={isMobile ? "2rem" : "2.5rem"}
      >
        {`최대 사은품으로\n 보답하겠습니다!`}
      </Typography>
      <Stack bgcolor={color.white} p={isMobile ? 1 : 3}>
        <Typography
          textAlign={"center"}
          letterSpacing={-0.8}
          variant="h3"
          fontWeight={"bold"}
          mx={"auto"}
          fontSize={isMobile ? "1.3rem" : "2rem"}
          py={3}
        >
          실시간 신청현황
        </Typography>

        <Grid container>
          {["신청일", "성함", "연락처", "신청현황"].map((item, idx) => {
            return (
              <Grid item key={idx} xs={3}>
                <Typography
                  bgcolor={"black"}
                  sx={{
                    minWidth: isMobile ? "40px" : "80px",
                    color: color.white,
                    fontSize: isMobile ? "0.7rem" : "1.5rem",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    py: 2,
                  }}
                >
                  {item}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Grid container height={500} sx={{ overflow: "hidden" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              animation: "marquee 15s linear infinite",
              overflow: "hidden",
              height: "1000px", // 컨테이너의 높이를 제한
              "@global": {
                "@keyframes marquee": {
                  "0%": { transform: "translateY(0)" },
                  "100%": { transform: "translateY(-100%)" },
                },
              },
            }}
          >
            {initArr.map((item, idx) => (
              <Grid container key={idx} sx={{ flexWrap: "nowrap" }}>
                <Grid item xs={3} sx={{ textAlign: "center", py: 2 }}>
                  <Typography
                    sx={{
                      minWidth: isMobile ? "40px" : "80px",
                      fontSize: isMobile ? "0.7rem" : "1.5rem",
                    }}
                  >
                    {item.date}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center", py: 2 }}>
                  <Typography
                    sx={{
                      minWidth: isMobile ? "40px" : "80px",
                      fontSize: isMobile ? "0.7rem" : "1.5rem",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ textAlign: "center", py: 2 }}>
                  <Typography
                    sx={{
                      minWidth: isMobile ? "40px" : "80px",
                      fontSize: isMobile ? "0.7rem" : "1.5rem",
                    }}
                  >
                    {item.phone}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <Typography
                    sx={{
                      bgcolor: "#541c7c",
                      px: 2,
                      py: 0.5,
                      borderRadius: 10,
                      minWidth: isMobile ? "40px" : "80px",
                      fontSize: isMobile ? "0.7rem" : "1.5rem",
                    }}
                  >
                    {item.status}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default React.memo(Section08);
