import { Fade } from '@mui/material';
import React, { useRef } from 'react';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section11 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  const handleClick = () => {
    window.location.href = 'http://pf.kakao.com/_UMjxkxj/chat';
    // 여기에 원하는 로직 추가
  };

  return (
    <Fade in={isVisible} timeout={1000}>
      <div ref={sectionRef} onClick={handleClick}>
        <CommonImage
          width="100%"
          sx={{ mt: -1, cursor: 'pointer' }}
          src={sectionImages.Section11Main}
        />
      </div>
    </Fade>
  );
};

export default Section11;
