import { Fade } from '@mui/material';
import React, { useRef } from 'react';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section07 = ({ isMobile }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);
  const handleClick = () => {
    window.location.href =
      'https://www.ictmarket.or.kr:8443/ajax/ajax3.do?id=PRE0000173100&seq=1&yn=1';
    // 여기에 원하는 로직 추가
  };
  return (
    <Fade in={isVisible} timeout={1000}>
      <div ref={sectionRef} onClick={handleClick}>
        <CommonImage
          width="100%"
          sx={{ mt: -1, cursor: 'pointer' }}
          src={sectionImages.Section07Main}
        />
      </div>
    </Fade>
  );
};

export default Section07;
