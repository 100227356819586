import { Box, Fade, Stack } from '@mui/material';
import React, { useRef } from 'react';
import { sectionImages } from '../images';

import CommonImage from './CommonImage';
import useFadeInOnScroll from '../controller/useFadeInOnScroll';

const Section01v3 = ({ isMobile, isDesktop }) => {
  const sectionRef = useRef(null);
  const isVisible = useFadeInOnScroll(sectionRef);

  return (
    <Stack>
      <Stack
        pt={2}
        pb={10}
        maxWidth={1000}
        margin={'auto'}
        alignItems={'center'}
      >
        <CommonImage
          width="80%"
          sx={{ mt: 1, mb: -2 }}
          src={sectionImages.Section02FormHead}
        />
      </Stack>
    </Stack>
  );
};

export default Section01v3;
