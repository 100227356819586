import Section0101Main from './section1_01_web_imgcopy.jpg';
import Section0101Text from './section1_01_web_text.png';
import Section0102Main from './section1_02_web_imgcopy.jpg';
import Section0102Text from './section1_02_web_text.png';
import Section02Main from './section2_web_img.jpeg';
import Section02Form2 from './section2_web_form_v2.png';
import Section02Form2Background from './section2_web_form_v2_background.png';
import Section02Form2BackgroundMobile from './section2_web_form_v2_background_mobile.png';
import Section02Form2Title from './section2_web_form_v2_title.png';
import Section02FormHead from './section2_web_form_head.png';
import Section02FormButton from './section2_web_form_btn.png';

import Section03Main from './section3_web_img.jpeg';
import Section04Main from './section4_web_img.jpeg';
import Section05Main from './section5_web_img.jpeg';
import Section05MainPNG from './section5_web_img.png';
import Section06Main from './section6_web_img.jpg';
import Section07Main from './section7_web_img.jpg';
import Section10Main from './section10_web_img.jpg';
import Section11Main from './section11_web_img.png';
import Section12Main from './section12_web_img.png';
import Qna from './qna.jpg';
import NotiMb from './noti_mb.jpg';
import NotiPC from './noti_pc.jpg';

import Qna3 from './Qna3.jpeg';
import Logo from './logo.png';
import LGLogo from './LG.png';
import SKLogo from './SK.png';
import KTLogo from './KT.png';

export const sectionImages = {
  Section0101Main,
  Section0101Text,
  Section0102Main,
  Section0102Text,
  Section02Main,
  Section02Form2,
  Section02Form2Background,
  Section02Form2BackgroundMobile,
  Section02Form2Title,
  Section02FormHead,
  Section02FormButton,
  Section03Main,
  Section04Main,
  Section05Main,
  Section05MainPNG,
  Section06Main,
  Section07Main,
  Section10Main,
  Section11Main,
  Section12Main,
  Qna,
  NotiMb,
  Qna3,
  NotiPC,
  Logo,
  LGLogo,
  SKLogo,
  KTLogo,
};
