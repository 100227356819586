import React, { useRef } from 'react';
import { Box, Container } from '@mui/material';
import '../css/Section05.css';
import CommonImage from './CommonImage';
import { sectionImages } from '../images';
import { useNavigate } from 'react-router-dom';

const Section05 = () => {
  const navigate = useNavigate();
  const sectionRef = useRef(null);

  const handleClick = () => {
    navigate('/calc');
    window.scrollTo(0, 0);
    // 여기에 원하는 로직 추가
  };
  return (
    <Container disableGutters>
      <Box ref={sectionRef} bgcolor={'#541c7c'} onClick={handleClick}>
        <CommonImage
          width="100%"
          sx={{
            mt: -1,
            cursor: 'pointer',
            animation: 'pulse 1s infinite',
            '@keyframes pulse': {
              '0%, 50%': { transform: 'scale(1)' },
              '50%': { transform: 'scale(0.9)' },
            },
          }}
          src={sectionImages.Section05MainPNG}
        />
      </Box>
    </Container>
  );
};

export default Section05;
