import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home, Noti, Qna, Calc, Admin, Form, NotFound } from './pages';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/noti" element={<Noti />} />
        <Route path="/qna" element={<Qna />} />
        <Route path="/calc" element={<Calc />} />
        <Route path="/form" element={<Form />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
